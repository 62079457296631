import React from 'react';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import Layout from '../components/Layout';

import Card from '../components/Card';
import OsmMap from '../components/OsmMap';

import picPlaquette from '../assets/images/event/plaquette.png';
import picPresse1 from '../assets/images/event/presse1.jpg';
import picPresse2 from '../assets/images/event/presse2.jpg';
import pic1 from '../assets/images/event/slideshow/1.jpg';
import pic2 from '../assets/images/event/slideshow/2.jpg';
import pic3 from '../assets/images/event/slideshow/3.jpg';
import pic4 from '../assets/images/event/slideshow/4.jpg';
import pic5 from '../assets/images/event/slideshow/5.jpg';
import pic6 from '../assets/images/event/slideshow/6.jpg';
import pic7 from '../assets/images/event/slideshow/7.jpg';
import pic8 from '../assets/images/event/slideshow/8.jpg';
import pic9 from '../assets/images/event/slideshow/9.jpg';
import pic10 from '../assets/images/event/slideshow/10.jpg';
import pic11 from '../assets/images/event/slideshow/11.jpg';
import pic12 from '../assets/images/event/slideshow/12.jpg';

const EventPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Concert d'AKM à Bercy</h2>
          <p>Le 12/20/2022 - 22h30 à bercy</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <section>
            <h3 className="major">Le concert</h3>
            <br/>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue. Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. </p>
            <button className="default">Réserver</button>
          </section>
          <br/>
          <br/>
          <br/>
          <section>
            <h3 className="major">Informations pratiques</h3>
            <p> Le concert ce déroule le <b>22/01/2023</b> à <b>22:30</b> à <b>Bercy</b> au <b>14 rue de Bercy, Paris 75014</b></p>
         
          </section>
          <br/>
          <OsmMap lat="44.5471869" lng="6.4212282" text="Plan d'eau d'Embrun" />
          <br/>
          <br/>
          <br/>

          <h3 className="major">concerts à venir</h3>
          <section className="features">

            <Card type='agenda' path="/concert" title="Mickael Gat à la Belleviloise" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." date="01/11/2022 à 20h30" place="La bellevilloise" button="Réservez"/>
            <Card type='agenda' path="/concert" title="Concert d'AKM à Bercy" image="{pic1}" tagline="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus." date="01/12/2023 à 22h30" place="Bercy" button="Réservez"/>
            

          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default EventPage;
